window.recalculateData = recalculateData;

// SLIDER
$("#hypoteka_DobaSplaceni").slider();
$("#hypoteka_DobaSplaceni").on("slide", function (slideEvt) {
  $("#hypoteka_DobaSplaceniSliderVal").text(slideEvt.value);
});

var formPart2 = document.getElementById('form-part-2');

formPart2.addEventListener('shown.bs.collapse', function () {
  $('html, body').animate({
    scrollTop: $("#form-part-2").offset().top
  }, 1000);
  $("#show-button").addClass('disabled');
})

$(document).ready(function () {
  recalculateData();
});


$("#hypoteka_Pujcka").change(function () {
  var cenaNemovitosti = $("#hypoteka_CenaNemovitosti").attr('val');
  var min = parseFloat($(this).attr('min'));
  var pujcka = $(this).val();
  pujcka = pujcka.replace(/ /g, "");
  pujcka = pujcka.replace("Kč", "");

  if (pujcka < min) {
    pujcka = min;
  }
  $(this).attr('val', pujcka);
  separate($(this), " ");
  if (pujcka > (cenaNemovitosti * 0.9)) {
    $("#hypoteka_Pujcka").attr('val', cenaNemovitosti * 0.9);
    recalculateData();
    separate($("#hypoteka_Pujcka"), " ");
    $("#msg-pujcka").slideDown(function () {
      setTimeout(function () {
        $("#msg-pujcka").slideUp();
      }, 5000);
    });
  } else {
    recalculateData();
  }

});

$("#hypoteka_CenaNemovitosti").change(function () {
  let pujcka = $("#hypoteka_Pujcka").attr('val');

  var price = $(this).val();
  var min = parseFloat($(this).attr('min'));
  price = price.replace(/ /g, "");
  price = price.replace("Kč", "");

  if (price < min) {
    cenaNemovitosti = min;
  } else {
    cenaNemovitosti = price;
  }
  $(this).attr('val', cenaNemovitosti);
  separate($(this), " ");
  if (pujcka > (cenaNemovitosti * 0.9)) {
    $("#hypoteka_Pujcka").attr('val', cenaNemovitosti * 0.9);
    recalculateData();
    separate($("#hypoteka_Pujcka"), " ");
    $("#msg-pujcka").slideDown(function () {
      setTimeout(function () {
        $("#msg-pujcka").slideUp();
      }, 5000);
    });
  }
  recalculateData();
});

$("#hypoteka_MesicniPrijem").change(function () {
  var min = parseFloat($(this).attr('min'));
  var price = $(this).val();
  price = price.replace(/ /g, "");
  price = price.replace("Kč", "");

  if (price < min) {
    price = min;
  }
  $(this).attr('val', price);
  separate($(this), " ");
});

$("#hypoteka_DobaSplaceni").change(function () {
  recalculateData();
});

$("#hypoteka_DobaFixace").change(function () {
  recalculateData();
});


function recalculateData() {
  let pujcka = parseFloat($("#hypoteka_Pujcka").attr('val'));
  let cenaNemovitosti = parseFloat($("#hypoteka_CenaNemovitosti").attr('val'));
  let dobaSplaceni = $("#hypoteka_DobaSplaceni").val();
  let dobaFixace = $("#hypoteka_DobaFixace").val();
  let mesicniSplatka = parseFloat($("#hypoteka_MesicniSplatka").attr('val'));

  $.ajax({
    url: "/calc",
    dataType: 'json',
    data: { pujcka, cenaNemovitosti, dobaSplaceni, dobaFixace, mesicniSplatka },
    type: 'POST',
    success: function (data) {
      $("#hypoteka_MesicniSplatka").attr('val', data['mesicni_splatka']);
      separate($("#hypoteka_MesicniSplatka"), " ");
      $(".urokova-sazba-value").html(data['urokova_sazba']);
    }
  });
}